export const starsKeowee = {
    "type": "FeatureCollection",
    "name": "keowee_stars",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
    { "type": "Feature", "properties": { "BASIN_NAME": "Keowee", "SUBBASIN_ID": "BADC_KEO", "DisplayName": "Bad Creek Reservoir", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.0059, "LON": -83.0175}, "geometry": { "type": "Point", "coordinates": [ -83.0175, 35.0059] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Keowee", "SUBBASIN_ID": "JOCA_KEO", "DisplayName": "Lake Jocassee", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.9604, "LON": -82.9189}, "geometry": { "type": "Point", "coordinates": [ -82.9189, 34.9604 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Keowee", "SUBBASIN_ID": "KEOW_KEO", "DisplayName": "Lake Keowee", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.8011, "LON": -82.886}, "geometry": { "type": "Point", "coordinates": [ -82.886, 34.8011 ] } },
    ]
    }
    