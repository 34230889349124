import Plot from "react-plotly.js";
// import {choosePlotTraceName} from "./PlotEvaluateHelpers";
import {formatPlotData,
  generateLineStyle,
  generateMarkerStyle,
  chooseEvaluateYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  formatNameForTitle,
  chooseFill,
  chooseFillColor,
  formatDateForTitle,
  addDays,
  subtractDays,
  setYRange,
  choosePlotTraceName,
  chooseLegendGroup
} from "./PlotCustomHelpers";
import "./MainPlot.css";
import { MoreInfo } from "../Layouts/MoreInfo";
import { createShapesMainEvaluate } from "./ShapesEvaluate";
import { createAnnotationsCustom } from "./AnnotationsCustom";

export const MainEvaluatePlot = ({ selectedBasin, allTs, thresholds }) => {
  const timeseries = allTs.filter(ts => (ts.header.locationId).startsWith(selectedBasin));
  const processedData = formatPlotData(timeseries);
  
  
  const notRegularTS = (name) => {
    //console.log("name", name);
    if (
      /*name === "ThresholdsBooleanThreshold" ||
       name === "ThresholdsCustomBooleanThreshold"||
      name === "ThresholdsCustomBooleanThresholdundefined" || 
      name === "ThresholdsCustomValueThresholdundefined" || */
      name === "Preprocess_PI" ||
      //name.includes( "QR") ||
      //name.includes("Unadj") ||
      name.includes("UNADJ") ||
      name.includes("Boolean") ||
      name.includes("ADJUSTQ_NORW_YAD_GFS_Forecast_QR_undefined")  //This needs to be investiaged further.


    ) {
      return true;
    } else {
      return false;
    }
  };

  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      //console.log("MainEvaluatePlot");
      //console.log("Name:", ts.name);
      let tsObject;
      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseEvaluateYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          showlegend:
            ts.name.includes("Merge_MAP_") 
              ? false
              : true,
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
          hovertemplate: 
            '%{y:.2f} in' +
            '<br>%{x}<br>' ,
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseEvaluateYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),
          hovertemplate: 
            '%{y:.2f}' +
            '<br>%{x}<br>' ,
          showlegend:
            ts.name.includes("Preprocess_HRRR_grids_to_FMAP") ||
            ts.name.includes("Preprocess_GFS_grids_to_FMAP") ||
            ts.name.includes("Preprocess_NBM_grids_to_FMAP") ||
            ts.name.includes("Preprocess_ECMWF_grids_to_FMAP") ||
            (ts.name.includes("ADJUSTQ") && !ts.name.includes("Unadj"))
            
              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
        };
      }
      //console.log("tsObject", tsObject);
      return tsObject;
      
    })
    .sort((a, b) => {
      // Define the desired order
      const customOrder = ["GFS","ECMWF","HRRR","SIM", "OBS", "MRMS"];
    
      // Helper function to get the index of a name in customOrder
      const getOrder = (name) => {
        if (name.includes("GFS")) return customOrder.indexOf("GFS");
        if (name.includes("ECMWF")) return customOrder.indexOf("ECMWF");
        if (name.includes("HRRR")) return customOrder.indexOf("HRRR");
        if (name.includes("SIM")) return customOrder.indexOf("SIM");
        if (name.includes("OBS")) return customOrder.indexOf("OBS");
        if (name.includes("MRMS")) return customOrder.indexOf("MRMS");
        return customOrder.length; // For items not in customOrder, place them last
      };
    
      // Compare based on the custom order
      const orderA = getOrder(a.name);
      const orderB = getOrder(b.name);
    
      return orderA - orderB; // Sort by custom order
    });

  const shapesArray = createShapesMainEvaluate(
    selectedBasin
  );
/*   const annotationsArray = createAnnotationsCustom(
    timeseries,
    thresholds,
    selectedBasin
  ); */

  return (
    <div className="main-plot-custom">
      <div className="plot-outer">
        <Plot
          data={generatePlotDataFormat}
            layout={{
              autosize: true,
              title: {
                text:
                formatNameForTitle(selectedBasin) +
                  // selectedBasin.split(/(?=[A-Z])/).join(" ") +
                  ": " +
                  formatDateForTitle(
                    plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                      .forecastDateTime.date + "T" +
                      plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                        .forecastDateTime.time
                  ),
                font: {
                  color: "black",
                  size: 20
                }
              },
              legend: { y: -0.1, orientation: "h" },
              showlegend: true,
              hovermode: 'x unified',
              yaxis1: {
                autoscale: true,
                title: {
                  text: "Streamflow<br> (cfs)",
                  font: {
                    size: 10,
                  },
                },
                gridcolor: "lightgrey",
                hoverformat: ".0f",
                mirror: "all",
                showgrid: true,
                showline: true,
                tickformat: ",",
                // zeroline: true,
                domain: [0, 0.43],
                rangemode: "tozero",
                tickfont: {
                  size: 11,
                },
              },
            yaxis2: {
              autoscale: true,
              title: {
                text: "Accum. Precip<br>(in)",
                font: {
                  size: 10
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              // zeroline: true,
              domain: [0.45, 0.73],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y2"),
              tickfont: {
                size: 11,
              }
            },
            yaxis3: {
              autoscale: true,
              title: {
                text: "Hrly. Precip<br>(in)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              // zeroline: true,
              domain: [0.75, 1.00],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y3"),
              tickfont: {
                size: 11,
              },
            },
            xaxis: {
              title: {
                text: "Datetime",
                font: {
                  size: 11,
                },
              },
              gridcolor: "lightgrey",
              mirror: "all",
              showgrid: true,
              showline: true,
              // zeroline: true,
              range: [subtractDays(Date.now(), 10), addDays(Date.now(), 10)],
              type: 'date',
              tickfont: {
                size: 11,
              },
            },
            // margin: { r: 20 },
            margin: { t: 45, r:8},
            //annotations: annotationsArray,
            shapes: shapesArray,
            // shapes: [{
            //   type: 'line',
            //   x0: Date.now(),
            //   y0: 0,
            //   x1: Date.now(),
            //   yref: 'paper',
            //   y2: 0.95,
            //   line: {
            //     color: 'red',
            //     width: 1,
            //     dash: 'dot'
            //   }
            // }],
          
          }}
          useResizeHandler={true}
          className="plot-inner"
          />
    </div>
    <div className="info-div">
      <MoreInfo name={selectedBasin} />
    </div>
  </div>
  );
};