export const starsPigeon = {
    "type": "FeatureCollection",
    "name": "pigeon_stars",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "WALT_PIG",
                "DisplayName": "Walters Lake",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.6935,
                "LON": -83.0517
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-83.0517, 35.6935]
            }
        },
    ]
}
