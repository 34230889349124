export const squaresPigeon = {
    "type": "FeatureCollection",
    "name": "pigeon_stars",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "LOGA_PIG",
                "DisplayName": "Lake Logan",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.4259,
                "LON": -82.9198
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-82.9198, 35.4259]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "BETH_PIG",
                "DisplayName": "West Fork Pigeon River at Bethel",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.4601,
                "LON": -82.9002
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-82.9002, 35.4601]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "CANT_PIG",
                "DisplayName": "Pigeon River near Canton",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.5189,
                "LON": -82.8441
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-82.8441, 35.5189]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "CATA_PIG",
                "DisplayName": "Cataloochee Creek near Cataloochee",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.6677,
                "LON": -83.0711
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-83.0711, 35.667]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "EFOR_PIG",
                "DisplayName": "East Fork Pigeon River near Canton",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.4609,
                "LON": -82.8697
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-82.8697, 35.4609]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "HEPC_PIG",
                "DisplayName": "Pigeon River near Hepco",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.6324,
                "LON": -82.9892
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-82.9892, 35.6324]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "BASIN_NAME": "Pidgeon",
                "SUBBASIN_ID": "WATE_PIG",
                "DisplayName": "Pigeon River near Waterville",
                "TabGroup": "Main-Res Inflows",
                "Point_Type": "Main",
                "LAT": 35.7816,
                "LON": -83.1098
            },
            "geometry": {
                "type": "Point",
                "coordinates": [-83.1098, 35.7816]
            }
        }
    ]
}
