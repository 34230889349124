//Plot formatting & data prep

// We have an array of timeseries data, one item in the array is a given ts for the location
export const formatPlotData = (data) => {
  let processedTimeseries = [];
  data.forEach((ts) => {
    if (ts.events) {
      // loop through the single ts and grab all the
      // x + y coords formatted in two separate arrays
      const xCoords = getXCoords(ts.events);
      const yCoords = getYCoords(ts.events);
      /*if(ts.header.locationId == "PLEA_CAT"){
        console.log("TS: ", ts.header.moduleInstanceId);
      }*/

      // create a new object with the name of the ts,
      //the basin id(aka the name of the basin),
      //the x coords and the y coords
      const newObject = {
        name: ts.header.moduleInstanceId === "Preprocess_PI" || ts.header.moduleInstanceId.includes("RW")  //The observed (external historical) data is all in Preprocess_PI.  To differentiate, the parameterId is appended.
        ? ts.header.moduleInstanceId +"_"+ ts.header.parameterId + "_" + ts.header.qualifierId[1]
        : ts.header.qualifierId
          ? ts.header.moduleInstanceId + ts.header.qualifierId[0] + ts.header.qualifierId[1]
          : ts.header.moduleInstanceId,
        basin: ts.header.locationId,
        x: xCoords,
        y: yCoords,
        forecastDateTime: ts.header.forecastDate,
      };
      processedTimeseries.push(newObject);



    }
  });
  /*if(processedTimeseries[0].basin == "JAME_CAT"){
    console.log("name: ", processedTimeseries[0].basin);
    console.log("processedTS: ", processedTimeseries);
  }*/

  
  return processedTimeseries;
};

//Get coordinates
const getXCoords = (array) => {
  const xCoords = [];
  array.forEach((event) => {
    xCoords.push(event.date + " " + event.time);
  });
  return xCoords;
};

const getYCoords = (array) => {
  const yCoords = [];
  array.forEach((event) => {
    yCoords.push(parseFloat(event.value));
  });
  return yCoords;
};

//Colors
export const HRRR_color ="rgba(255, 165, 0, 0.95)";
export const GFS_color ="rgba(192, 0, 0, 0.95)";
export const NBM_color = "rgba(0, 176, 80, 0.95)";
export const ECMWF_color = "rgba(143, 87, 255, 0.95)";
export const MRMS_color = "rgba(107, 107, 120, 1)";
export const USGS_gage_color = "rgba(250, 160, 5, 1)";
export const BestAvailable_color = "rgba(255, 165, 0, 0.95)";
export const Unadj_color = "rgba(104, 118, 153, 0.5)";
export const Eng_color = "rgba(255, 171, 50, 0)";

//Line Style
export const generateLineStyle = (name) => {
  let lineStyle = {};
  if (name.includes("HRRR")
  ) {
    lineStyle = { color: HRRR_color, width: 2 };
    if(name.includes("UNREG")){
      lineStyle = { color: HRRR_color, width: 2, dash: "dot" };
    }
    if(name.includes("QG")){
      lineStyle = { color: HRRR_color, width: 2, dash: "dot" };
    }
  } 
  if (name.includes("GFS")
  ) {
    lineStyle = { color: GFS_color, width: 2};
    if(name.includes("UNREG")){
      lineStyle = { color: GFS_color, width: 2, dash: "dot" };
    }
    if(name.includes("QG")){
      lineStyle = { color: GFS_color, width: 2, dash: "dot" };
    }
  } 
  if (name.includes("NBM")
  ) {
    lineStyle = { color: NBM_color, width: 2};
    if(name.includes("UNREG")){
      lineStyle = { color: NBM_color, width: 2, dash: "dot" };
    }
    if(name.includes("QG")){
      lineStyle = { color: NBM_color, width: 2, dash: "dot" };
    }
  } 
  if (name.includes("ECMWF")
  ) {
    lineStyle = { color: ECMWF_color, width: 2};
    if(name.includes("UNREG")){
      lineStyle = { color: ECMWF_color, width: 2, dash: "dot" };
    }
    if(name.includes("QG")){
      lineStyle = { color: ECMWF_color, width: 2, dash: "dot" };
    }
  }
  if (name.includes("MRMS")
  ) {
    lineStyle = { color: MRMS_color, width: 2, dash: "solid" };
  } 
  if (name.includes("Obs")
  ) {
    lineStyle = { color: MRMS_color, width: 3, dash: "solid" };
  } 
  if (name.includes("Threshold")
  ) {
    lineStyle = { color: "black", width: 3, dash: "solid" };
  } 
  if (name.includes("Preprocess_QR")
    
  ) {
    lineStyle = { color: USGS_gage_color, width: 2.5, dash: "dot" };
  } 
  if (name.includes("BestAvailable")
  ) {
    lineStyle = { color: BestAvailable_color, width: 2, dash: "solid" };
  } 
  if (name.includes("Unadj")
  ) {
    lineStyle = { color: Unadj_color, width: 2, dash: "solid" };
  } 
  if (name.includes("VE")
  ) {
    lineStyle = { color: Eng_color, width: 2, dash: "dot" };
  } 
  if (name.includes("Preprocess_PI")
  ) {
    lineStyle = { color: MRMS_color, width: 2, dash: "solid" };
  } 
  if (name.includes("Preprocess_PI_QR")
  ) {
    lineStyle = { color: MRMS_color, width: 3, dash: "solid" };
  } 
  
  


  return lineStyle;
};

//Marker style
export const generateMarkerStyle = (name) => {
  let markerStyle = {};
  if (name.includes("HRRR")) {
    markerStyle = { color: HRRR_color };
  } 
  if (name.includes("GFS")) {
    markerStyle = { color: GFS_color };
  } 
  if (name.includes("NBM")) {
    markerStyle = { color: NBM_color };
  } 
  if (name.includes("ECMWF")) {
    markerStyle = { color: ECMWF_color};
  } 
  if (name.includes("MRMS")) {
    markerStyle = { color: MRMS_color };
  } 
  if (name.includes("Obs")) {
    markerStyle = { color: MRMS_color };
  }
  if (name.includes("BestAvailable")) {
    markerStyle = { color: BestAvailable_color };
  } 
  if (name.includes("Unadj")) {
    markerStyle = { color: Unadj_color };
  } 
  if (name.includes("Unadj")) {
    markerStyle = { color: Unadj_color };
  } 

  return markerStyle;
};

//Legend
export const chooseLegendGroup = (name) => {
  let legendGroup;
  if (name.includes("ECMWF")) {
    legendGroup = "ECMWF";
  } 
  if (name.includes("HRRR")) {
    legendGroup = "HRRR";
  } 
  if (name.includes("NBM")) {
    legendGroup = "NBM";
  } 
  if (name.includes("GFS") && !name.includes("Unadj")) {
    //console.log("name: ", name);
    legendGroup = "GFS";
  } 
  if (name.includes("HRRR_ForecastUnadj")) {
    legendGroup = "Unadj";
  } 
  if (name.includes("HRRR_ForecastObs")) {
    legendGroup = "Obs";
  } 

  if (name.includes("Preprocess_PI")) {
    legendGroup = "OBS";
  } 
  if (name.includes("MRMS")) {
    legendGroup = "MRMS";
  } 
  if (name.includes("Unadj")) {
    legendGroup = "SIM";
  } 
  if (name.includes("Thresh")) {
    legendGroup = "THRESH";
  } 
  return legendGroup;
}

/*
Valid for both Inflow Page & Headwaters Page
Y Axises Order & Contents
y4 Hourly Precip & y6 Accum Precip
y3 Inflow, Unregulated Flow
y2 OutFlow, Unitflow & y5 Generation
y1 Pool Elevation


*/
export const chooseCustomYAxis = (name) => {
  //console.log("name: ",name);
  
  let yaxis;
  //Forecast Precip
  if (name.includes("MRMS")) {
    yaxis = "y4";
  } 
  if (name.includes("Merge_MAP")) {
    if (name.includes("cumulative")) {
    yaxis = "y6";
  } 
    else{
      yaxis = "y4";
    }
  }
  
  //Cumulative Precip
  if (name.includes("FMAPcumulative")) {
    yaxis = "y6";
  } 

  
  //RES
  if (name.includes("QI")) {
    yaxis = "y3";
  } 
  if (name.includes("QT")) {
    yaxis = "y2";
  }
  if (name.includes("QG")) {
    yaxis = "y2";
  }

  if (name.includes("HP")) {
    yaxis = "y1";
  } 
  if (name.includes("VE")) {
    yaxis = "y5";
  } 
  if (name.includes("UNREG")) {
    yaxis = "y3";
  } 
  //OBS
  if (name.includes("Preprocess_PI_HP")) {
    yaxis = "y1";
  } 
  if (name.includes("Preprocess_PI_QT")) {
    yaxis = "y2";
  } 
  if (name.includes("Preprocess_PI_QR")) {
    yaxis = "y2";
  } 
  if (name.includes("Preprocess_PI_QR")) {
    yaxis = "y2";
  } 
  if (name.includes("Threshold")) {
    yaxis = "y3";
  } 

  return yaxis;
};

export const chooseEvaluateYAxis= (name) => {
  //console.log("name: ",name);
  let yaxis;
  if (name.includes("_Forecast_customECMWF")) {
    yaxis = "y3";
  } 
  if (name.includes("_Forecast_customHRRR")) {
    yaxis = "y3";
  } 
  if (name.includes("_Forecast_customNBM")) {
    yaxis = "y3";
  } 
  if (name.includes("_Forecast_customGFS")) { 
    yaxis = "y3";
  } 
  if (name.includes("_Forecast_customBestAvailable")) {
    yaxis = "y3";
  } 
  if (name.includes("_HRRR_ForecastObs")) {
    yaxis = "y3";
  } 
  if (name.includes("QPE")) {
    yaxis = "y3";
  } 
  if (name.includes("MRMS")) {
    yaxis = "y3";
  } 
  //Precip
  if (name.includes("cumulative")) {
    yaxis = "y2";
  } 

  if (name.includes("ADJUST")) {
    yaxis = "y1";
  } 
  if (name.includes("Threshold")) {
    yaxis = "y1";
  } 
  if (name.includes("Inflow")) {
    yaxis = "y1";
  } 


  return yaxis;
};



export const chooseFill = (name) => {
  let fill;
  //Review
  if (name === "Merge_MAP_HRRR_Tuck_Forecast_customHRRR" ||
      name === "Merge_MAP_HRRR_Nant_Forecast_customHRRR" 
  ) {
    fill = "tonexty";
  } else {
    fill = null;
  }
  return fill;
};

export const chooseFillColor = (name) => {
  let fillColor;
  //Review
  if (
    name === "Merge_MAP_HRRR_Tuck_Fore" ||
    name === "Merge_MAP_HRRR_N" 
  ) {
    fillColor = "rgba(0, 102, 255, 0.3)";
  } else {
    fillColor = null;
  }
  return fillColor;
};

export const chooseType = (name) => {
  let type;
   if (name.includes("Merge_MAP_")) {
    type = "bar";
  }  
  if (name.includes("cumulative")) {
    type = "line";
  } 
  if (name.includes("MRMS")) {
    type = "bar";
  } 
  if (name.includes("ADJUST")) {
    type = "line";
  } 
  if (name.includes("Threshold")) {
    type = "line";
  } 

  return type;
};

export const chooseWidth = (name) => {
  let width;
  if (name.includes("Merge_MAP_")) {
    width = 3600000;
  } 
  if (name.includes("MRMS")) {
    width = 3600000;
  } 
  return width;
};

export const chooseOffset = (name) => {
  // we need this since the bars naturally plot to the right, but we want them to the left
  let offset;
  if (name.includes("Merge_MAP_")) {
    offset = -3600000;
  } 
  if (name.includes("MRMS")) {
    offset = -3600000;
  } 
  return offset;
};

//Time processing
export const findHindcastStart = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);
  return tsStart.setTime(tsStart.getTime());
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export const subtractDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export const findForecastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  const tsEnd = new Date(tsOfInterest?.x[0]);
  const dateOffset = 24 * 60 * 60 * 1000 * 7; //0.7 days
  return tsEnd.setTime(tsEnd.getTime() + dateOffset);
};

export const findPreMediumForecastMiddle = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);
  const dateOffset = 24 * 60 * 60 * 1000 * 0.7; //0.7 days
  return tsStart.setTime(tsStart.getTime() - dateOffset);
};

export const findPreMediumForecastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  return new Date(tsOfInterest?.x[0]);
};

export const findHindcastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  return new Date(tsOfInterest?.x[tsOfInterest.x.length - 1]);
};

export const findPostShortTermStart = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const rectangleStart = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 1.25; 
  return rectangleStart?.getTime() + dateOffset;
};

export const findPostShortTermEnd = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 11;
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  return rectangleEnd?.getTime() + dateOffset;
};

export const findPostShortTermMiddle = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 5.5; //5.5 days
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  return rectangleEnd?.getTime() + dateOffset;
};

export const formatDateForTitle = (originalDate) => {
  const dateObj = new Date(originalDate);
  return dateObj.toLocaleString({
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

//Thresholds
export const thresholdVisible = (array, basin) => {
  if (array.filter((item) => item.name === basin).length > 0) {
    return true;
  } else {
    return false;
  }
};
export const findThresholdValue = (array) => {
  const tsOfInterest = array.find(
    (ts) =>
      ts.header.moduleInstanceId === "ThresholdsCustom" &&
      ts.header.qualifierId[0] === "ValueThreshold"
  );
  if (tsOfInterest) {
    return parseInt(tsOfInterest?.events[0].value);
  } else {
    return 0;
  }
};
//Cumulative precip calcs
const findCenterOfPastPrecip = (forecastDateTime) => {
  const forecastStart = Date.parse(
    forecastDateTime.date + " " + forecastDateTime.time
  );
  // add 18 hours (64800000 ms in 18 hours)
  return new Date(forecastStart).getTime() + 64800000;
};

export const createPastCumulativePrecipValuesArray = (ts) => {
  // calculate the total precip amount in the 36 hours historic period
  const sum = !ts.events
    ? "No data"
    : ts.events
        .map((timestep) => parseFloat(timestep.value))
        .reduce((prev, next) => prev + next);

  return {
    wfpType: "accumulation_anno",
    aggregation_name: "SRPB",
    visible: true,
    xref: "x",
    yref: "paper",
    y: 0.98,
    xanchor: "center",
    // add function to find the center of the historic past 36 hour period
    x: findCenterOfPastPrecip(ts.header.startDate),
    yanchor: "middle",
    text: sum.toFixed(2),
    showarrow: false,
    font: {
      color: "rgb(80, 80, 80)",
      size: 11,
    },
  };
};

export const calcPastCumulativePrecip = (ts) => {
  // calculate the total precip amount in the 36 hours historic data period
  const sum = ts.y
    .map((value) => parseFloat(value))
    .reduce((prev, next) => prev + next);
  return sum.toFixed(2);
};

export const setYRange = (data, yaxis) => {
  // default y max is set to 1, but if the data have larger values than 1, this will scale the yaxis range up to match the data
  const thisYaxisTs = data.filter((ts) => {
    return ts.yaxis === yaxis;
  });

  let max = 1;

  thisYaxisTs.forEach((ts) => {
    ts.y.forEach((value) => {
      if (value > max) {
        max = value * 1.2;
      }
    });
  });

  return [0, max];
};

export const setYGenSpillRange = (data, yaxis) => {
  const turbineTs = data.filter((ts) => {
    return (
      ts.name.includes("Turbine") );
  });

  const genTs = data.filter((ts) => {
    return ( ts.name.includes("Gen"));
  });

  const totalTs = data.filter((ts) => {
    return (ts.name.includes("Total"));
  });

  const obsTs = data.filter((ts) => {
    return (ts.name.includes("OBS"));
  });

  const turbineYMaxes = turbineTs.map((ts) => Math.max(...ts.y));
  const totalYMaxes   = totalTs.map((ts) => Math.max(...ts.y));
  const obsYMaxes     = obsTs.map((ts) => Math.max(...ts.y));
  const genYMaxes     = genTs.map((ts) => Math.max(...ts.y));

  const turbineYMax = Math.max(...turbineYMaxes);
  const genYMax = Math.max(...genYMaxes);
  const outflowYMax   = Math.max(...[...turbineYMaxes, ...totalYMaxes, ...obsYMaxes]);



 if (yaxis == "y2"){

  return [0, outflowYMax * 1.1];
 }
 if (yaxis == "y5"){

/*   console.log("outflowYMax", outflowYMax);
  console.log("turbineYMax", turbineYMax);
  console.log("genYMax", genYMax);
  console.log("UpperRange", [0, (outflowYMax / turbineYMax) * genYMax * 1.1]); */

  if (isNaN((outflowYMax / turbineYMax) * genYMax )){
    return [0, 1];
  }
  else{
    return [0, (outflowYMax / turbineYMax) * genYMax * 1.1];

  }
 }

};

export const setYPoolRange = (selectedBasin, data, yaxis) => {
  const nonGageRes = ["NARR_YAD", "TUCK_YAD", "KERR_YAD", "HIGH_YAD", "FALL_YAD"];

  const thisYaxisTs = data.filter((ts) => {
    return ts.yaxis === yaxis;
  });

  if (nonGageRes.includes(selectedBasin) || nonGageRes.includes(selectedBasin.name)) {
    let max = -Infinity;
    let min = Infinity;

    thisYaxisTs.forEach((ts) => {
      ts.y.forEach((value) => {
        if (value > max) {
          max = value + 5; 
        }
        if (value < min) {
          min = value - 5; 
        }
      });
    });
    return [min, max];
  }

  return [80, 110]; 
};

//Swap Id for Basin Name
export const formatNameForTitle = (originalName) => {
  let newName;
  switch (originalName) {
    case "WOLF_TUC":
      newName = "Wolf Creek";
      break;
    case "TANA_TUC":
      newName = "Tanasee";
      break;
    case "BEAR_TUC":
      newName = "Bear Creek";
      break;
    case "CEDA_TUC":
      newName = "Cedar Cliff";
      break;
    case "GLEN_TUC":
      newName = "Glenville";
      break;
    case "TUCK_TUC":
      newName = "Tuckasegee";
      break;

    case "CEDA_CAT":
      newName = "Cedar Creek";
      break;
    case "FISH_CAT":
      newName = "Fishing Creek";
      break;
    case "GREA_CAT":
      newName = "Great Fall";
      break;
    case "HICK_CAT":
      newName = "Hickory";
      break;
    case "JAME_CAT":
      newName = "James";
      break;
    case "NORM_CAT":
      newName = "Norman";
      break;
    case "RHOD_CAT":
      newName = "Rhodiss ";
      break;
    case "WATR_CAT":
      newName = "Wateree";
      break;
    case "WYLI_CAT":
      newName = "Wylie";
      break;
    case "LOOK_CAT":
      newName = "Lookout Shoals";
      break;
    case "MOUN_CAT":
      newName = "Mountain Is.";
      break;
    
    case "RAIN_NAN":
      newName = "Rainbow Springs";
      break;
    case "LAKE_NAN":
      newName = "Nantahala Lake";
      break;
    case "WHIT_NAN":
      newName = "Whiteoak Creek";
      break;
    case "QUEE_NAN":
      newName = "Queens Creek";
      break;
    case "DICK_NAN":
      newName = "Dicks Creek";
      break;
    case "HYDR_NAN":
      newName = "Nantahala Station Bypass";
      break;

    case "BLEW_YAD":
      newName = "Blewett";
      break;
    case "FALL_YAD":
      newName = "Falls";
      break;
    case "HIGH_YAD":
      newName = "High Rock";
      break;
    case "TILL_YAD":
      newName = "Tillery";
      break;
    case "NARR_YAD":
      newName = "Narrows";
      break;
    case "TUCK_YAD":
      newName = "Tuckertown";
      break;
    case "KERR_YAD":
      newName = "Kerr Scott";
      break;

    case "GAGE_TUC":
      newName = "Tuckasegee near Cullowhee";
      break;
    case "CANE_TUC":
      newName = "Caney Fork";
      break;
    case "SAVA_TUC":
      newName = "Savannah Creek";
      break;
    case "CULL_TUC":
      newName = "Cullowhee Creek";
      break;
    case "BELW_TUC":
      newName = "Below Cullowhee";
      break;
    case "DILL_TUC":
      newName = "Dillsboro";
      break;

    case "PLEA_CAT":
      newName = "Pleasant Gardens";
      break;
    case "JOHN_CAT":
      newName = "Johns River";
      break;
    case "LOWL_CAT":
      newName = "Lower Little R";
      break;
    case "ROCK_CAT":
      newName = "Rocky Creek";
      break;
    case "SFOR_CAT":
      newName = "South Fork";
      break;
    case "SUGA_CAT":
      newName = "Sugar Creek";
      break;
    case "FCRK_CAT":
      newName = "Fishing Creek";
      break;
    case "LINV_CAT":
      newName = "Linville River";
      break;
    case "MCDO_CAT":
      newName = "McDowell Cr.";
      break;
    case "MIDL_CAT":
      newName = "Middle Little R";
      break;
    case "UPPL_CAT":
      newName = "Upper Little R";
      break;
    case "DUTC_CAT":
      newName = "Dutchmans Cr.";
      break;
    case "WARR_CAT":
      newName = "Warrior Fork";
      break;
    case "MORG_CAT":
      newName = "Abv Rhodiss ";
      break;
    case "BELW_CAT":
      newName = "Bowater (excluding Wylie)";
      break;



    case "ABBO_YAD":
      newName = "Abbotts Creek";
      break;
    case "ARAR_YAD":
      newName = "Ararat River";
      break;
    case "REDD_YAD":
      newName = "Reddies River";
      break;
    case "IRSH_YAD":
      newName = "Rocky at Irish";
      break;
    case "MOCK_YAD":
      newName = "S. Yadkin Mocksville";
      break;

    case "ELKC_YAD":
      newName = "Elk Creek";
      break;
    case "HUNT_YAD":
      newName = "Hunting Creek";
      break;
    case "LITT_YAD":
      newName = "Little River";
      break;
    case "LYAD_YAD":
      newName = "Little Yadkin";
      break;
    case "SECO_YAD":
      newName = "Second Creek";
      break;
    case "MITC_YAD":
      newName = "Mitchell River";
      break;
    case "ROAR_YAD":
      newName = "Roaring River";
      break;
    case "UWHA_YAD":
      newName = "Uwharrie River";
      break;
    case "NORW_YAD":
      newName = "Rocky at Norwood";
      break;
    case "ELKN_YAD":
      newName = "Elkin";
      break;
    case "ENON_YAD":
      newName = "Enon";
      break;
    case "WILK_YAD":
      newName = "Wilkesboro";
      break;
    case "YADC_YAD":
      newName = "Yadkin College";
      break;
    case "UPPR_YAD":
      newName = "Upper Yadkin";
      break;
    case "FISH_YAD":
      newName = "Fisher River";
      break;
    case "CONF_YAD":
      newName = "Confluence";
      break;
    case "PATT_YAD":
      newName = "Patterson";
      break;
    case "SYAD_YAD":
      newName = "S. Yadkin";
      break;
    case "ROCK_YAD":
      newName = "Rocky River";
      break;
    case "SILV_NAN":
      newName = "Silvermine Creek";
      break;
    case "OUTD_NAN":
      newName = "Outdoor Center";
      break;
    case "HEWI_NAN":
      newName = "Hewitt";
      break;
    case "PATT_NAN":
      newName = "Patterson";
      break;

    case "BETH_PIG":
        newName = "West Fork Pigeon River at Bethel";
        break;
    case "CANT_PIG":
        newName = "Pigeon River near Canton";
        break;
    case "CATA_PIG":
        newName = "Cataloochee Creek near Cataloochee";
        break;
    case "EFOR_PIG":
        newName = "East Fork Pigeon River near Canton";
        break;
    case "HEPC_PIG":
        newName = "Pigeon River near Hepco";
        break;
    case "LOGA_PIG":
        newName = "Lake Logan";
        break;
    case "WALT_PIG":
        newName = "Walters Lake";
        break;
    case "WATE_PIG":
        newName = "Pigeon River near Waterville";
        break;
    case "BADC_KEO":
          newName = "Bad Creek Reservoir";
          break;    
    case "EAST_KEO":
        newName = "Eastatoee Creek";
        break;
    case "JOCA_KEO":
        newName = "Lake Jocassee";
        break;
    case "KEOW_KEO":
        newName = "Lake Keowee";
        break;

//News
    case "HENR_CAT": newName = "Henry Fork"; break;
    case "ALLI_CAT": newName = "Allison Creek"; break;
    case "CAMD_CAT": newName = "Wateree River"; break;
    case "CRRH_CAT": newName = "Catawba River near Rock Hill"; break;
    case "FISY_CAT": newName = "Fishing Creek "; break;
    case "INDC_CAT": newName = "Indian Creek "; break;
    case "JACO_CAT": newName = "Jacob Fork"; break;
    case "KILL_CAT": newName = "Killian Creek "; break;
    case "LITS_CAT": newName = "Little Sugar Creek "; break;
    case "LONC_CAT": newName = "Long Creek near Bessemer"; break;
    case "LONG_CAT": newName = "Long Creek near Rhyne"; break;
    case "MCAL_CAT": newName = "McAlpine Creek "; break;
    case "SUGP_CAT": newName = "Sugar Creek "; break;
    case "WILC_CAT": newName = "Wildcat Creek "; break;
    case "DILL_TUC": newName = "Tuckasegee at Dillsboro"; break;
    case "RKHM_YAD": newName = "Pee Dee River near Rockingham"; break;
      



    default:
      newName = originalName;
  }
  return newName;
};


//Plot Trace
export const choosePlotTraceName = (name) => {
  //console.log("Trace name: ",name);
  let trace = name;
  if (trace.includes("Threshold")) {
      trace = "Threshold"
  } 

  if (trace.includes("Preprocess")) {
    if (trace.includes("MRMS")){
      trace = "MRMS";
    }
    else{
      trace = "OBS"
    }
  } 

  if (trace.includes("Merge_MAP")) {
    if (trace.includes("ECMWF")){
      trace = "ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "GFS";
    }
    if (trace.includes("HRRR")){
      trace = "HRRR";
    }
    if (trace.includes("NBM")){
      trace = "NBM";
    }
  }

  if (trace.includes("UNREG")) {
    if (trace.includes("ECMWF")){
      trace = "Unreg Inflow ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Unreg Inflow GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Unreg Inflow HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Unreg Inflow NBM";
    }
  }
  
  if (trace.includes("_QI_")) {
    if (trace.includes("ECMWF")){
      trace = "Total Inflow ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Total Inflow GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Total Inflow HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Total Inflow NBM";
    }
  }

  if (trace.includes("_HP_")) {
    if (trace.includes("ECMWF")){
      trace = "Pool ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Pool GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Pool HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Pool NBM";
    }
  }

  if (trace.includes("_QT_")) {
    if (trace.includes("ECMWF")){
      trace = "Total Outflow ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Total Outflow GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Total Outflow HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Total Outflow NBM";
    }
  }

  if (trace.includes("_VE_")) {
    if (trace.includes("ECMWF")){
      trace = "Gen ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Gen GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Gen HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Gen NBM";
    }
  }

  if (trace.includes("_QG_")) {
    if (trace.includes("ECMWF")){
      trace = "Turbine Outflow ECMWF";
    }
    if (trace.includes("GFS")){
      trace = "Turbine Outflow GFS";
    }
    if (trace.includes("HRRR")){
      trace = "Turbine Outflow HRRR";
    }
    if (trace.includes("NBM")){
      trace = "Turbine Outflow NBM";
    }
  }

  if (trace.includes("ADJUSTQ")) {
    if (trace.includes("ECMWF")){
      trace = "ECMWF";
    }
    if (trace.includes("GFS") && !trace.includes("Unadj")){
      trace = "GFS";
    }
    if (trace.includes("HRRR")){
      trace = "HRRR";
    }
    if (trace.includes("NBM")){
      trace = "NBM";
    }

    if (trace.includes("Unadj")){
      trace = "SIM";
    }
  }

/* 
  trace = trace.replace("NARR", "");
  trace = trace.replace("KERR", "");
  trace = trace.replace("JAME", "");
  trace = trace.replace("RHOD", "");
  trace = trace.replace("HICK", "");
  trace = trace.replace("LOOK", "");
  trace = trace.replace("NORM", "");
  trace = trace.replace("MOUN", "");
  trace = trace.replace("WYLI", "");
  trace = trace.replace("FISH", "");
  trace = trace.replace("CEDA", "");
  trace = trace.replace("WATR", "");
  trace = trace.replace("PLEA", "");
  trace = trace.replace("LINV", "");
  trace = trace.replace("MORG", "");
  trace = trace.replace("WARR", "");
  trace = trace.replace("JOHN", "");
  trace = trace.replace("LOWL", "");
  trace = trace.replace("ROCK", "");
  trace = trace.replace("SFOR", "");
  trace = trace.replace("SUGA", "");
  trace = trace.replace("FCRK", "");
  trace = trace.replace("MCDO", "");
  trace = trace.replace("MIDL", "");
  trace = trace.replace("UPPL", "");
  trace = trace.replace("DUTC", "");
  trace = trace.replace("BELW", "");
  trace = trace.replace("ELKC", "");
  trace = trace.replace("REDD", "");
  trace = trace.replace("WILK", "");
  trace = trace.replace("MITC", "");
  trace = trace.replace("FISH", "");
  trace = trace.replace("ARAR", "");
  trace = trace.replace("LYAD", "");
  trace = trace.replace("ELKN", "");
  trace = trace.replace("ENON", "");
  trace = trace.replace("ABBO", "");
  trace = trace.replace("IRSH", "");
  trace = trace.replace("NAN", "");
  trace = trace.replace("MOCK", "");
  trace = trace.replace("HUNT", "");
  trace = trace.replace("LITT", "");
  trace = trace.replace("SECO", "");
  trace = trace.replace("ROAR", "");
  trace = trace.replace("UWHA", "");
  trace = trace.replace("NORW", "");
  trace = trace.replace("YADC", "");
  trace = trace.replace("SYAD", "");
  trace = trace.replace("ROCK", "");
  trace = trace.replace("CONF", "");
  trace = trace.replace("GLEN", "");
  trace = trace.replace("THOR", "");
  trace = trace.replace("TANA", "");
  trace = trace.replace("WOLF", "");
  trace = trace.replace("BEAR", "");
  trace = trace.replace("CEDA", "");
  trace = trace.replace("GAGE", "");
  trace = trace.replace("CANE", "");
  trace = trace.replace("SAVA", "");
  trace = trace.replace("CULL", "");
  trace = trace.replace("BELW", "");
  trace = trace.replace("DILL", "");
  trace = trace.replace("LAKE", "");
  trace = trace.replace("DICK", "");
  trace = trace.replace("WHIT", "");
  trace = trace.replace("HYDR", "");
  trace = trace.replace("QUEE", "");
  trace = trace.replace("OUTD", "");
  trace = trace.replace("RAIN", "");
  trace = trace.replace("SILV", "");
  trace = trace.replace("HEWI", "");
  trace = trace.replace("PATT", "");
  trace = trace.replace("UPPR", "");
  trace = trace.replace("BLEW", "");
  trace = trace.replace("HIGH", "");
  trace = trace.replace("TILL", "");
  trace = trace.replace("TUCK", "");
  trace = trace.replace("FALL", "");
  trace = trace.replace("GREA", ""); 

  trace = trace.replace("YAD", "");
  trace = trace.replace("CAT", "");
  trace = trace.replace("TUC", "");
  trace = trace.replace("NAN", "");*/

  
  trace = trace.replace("RW_Catawba_Bridgewater","")
  trace = trace.replace("RW_Catawba_RhodhissToWylie","")
  trace = trace.replace("RW_Catawba_FishingCreekToWateree","")
  trace = trace.replace("RW_Yadkin_HighRockToBlewett","")
  trace = trace.replace("RW_Yadkin_WKerrScott","")
  trace = trace.replace("RW_Nantahala_Nantahala","")
  trace = trace.replace("RW_Nantahala_QueensCreek","")
  trace = trace.replace("RW_Tuckasegee_TennesseeCreekToCedarCliff","")
  trace = trace.replace("RW_Tuckasegee_GlenvilleToTuckasegee","")
  trace = trace.replace("RW_Pigeon_Walters","")
  trace = trace.replace("RW_Keowee_BadCreekToKeowee","")


  return trace;
};