export const squaresNant = {
"type": "FeatureCollection",
"name": "nant_squares",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "SILV_NAN", "DisplayName": "Silvermine Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.311677988608103, "LON": -83.609264872376627 }, "geometry": { "type": "Point", "coordinates": [ -83.591589183955307, 35.329316087316791 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "OUTD_NAN", "DisplayName": "Outdoor Center ", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.329634846425101, "LON": -83.6337121607299}, "geometry": { "type": "Point", "coordinates": [ -83.59831152306424, 35.332597229024714 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "HEWI_NAN", "DisplayName": "Hewitt ", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.295451912267438 , "LON": -83.67178935320932}, "geometry": { "type": "Point", "coordinates": [ -83.652170263782352, 35.304587482737432 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "RAIN_NAN", "DisplayName": "Rainbow Springs", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.08234944299339, "LON": -83.56621168315273 }, "geometry": { "type": "Point", "coordinates": [ -83.61879490047194, 35.127978529785508 ] } },

{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "DICK_NAN", "DisplayName": "Dicks Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.215835964355925, "LON": -83.692126035556285 }, "geometry": { "type": "Point", "coordinates": [ -83.668290873118778, 35.218535039880365 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "WHIT_NAN", "DisplayName": "Whiteoak Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.216917702778638 , "LON": -83.595634968250465 }, "geometry": { "type": "Point", "coordinates": [ -83.631558091559171, 35.228953665151153 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "HYDR_NAN", "DisplayName": "Nantahala Station Bypass", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.244610206400026 , "LON": -83.655130581499577 }, "geometry": { "type": "Point", "coordinates": [ -83.676685043959282, 35.271988639625434 ] } }

]
}
