export const starsCatawba = {
"type": "FeatureCollection",
"name": "catawba_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "JAME_CAT", "DisplayName": "James 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-82.01	 , "LAT":	35.785	}, "geometry": { "type": "Point", "coordinates": [ 	-82.01	,	35.785	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "RHOD_CAT", "DisplayName": "Rhodiss (Local	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.562	 , "LAT":	35.848	}, "geometry": { "type": "Point", "coordinates": [ 	-81.562	,	35.848	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "HICK_CAT", "DisplayName": "Hickory 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.372	 , "LAT":	35.806	}, "geometry": { "type": "Point", "coordinates": [ 	-81.372	,	35.806	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LOOK_CAT", "DisplayName": "Lookout Shoals 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.176	 , "LAT":	35.878	}, "geometry": { "type": "Point", "coordinates": [ 	-81.176	,	35.878	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "NORM_CAT", "DisplayName": "Norman 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.015	 , "LAT":	35.641	}, "geometry": { "type": "Point", "coordinates": [ 	-81.015	,	35.641	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MOUN_CAT", "DisplayName": "Mountain Is. 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.953	 , "LAT":	35.385	}, "geometry": { "type": "Point", "coordinates": [ 	-80.953	,	35.385	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WYLI_CAT", "DisplayName": "Wylie 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.119	 , "LAT":	35.177	}, "geometry": { "type": "Point", "coordinates": [ 	-81.119	,	35.177	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "FISH_CAT", "DisplayName": "Fishing Creek 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.764	 , "LAT":	34.766	}, "geometry": { "type": "Point", "coordinates": [ 	-80.764	,	34.766	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "GREA_CAT", "DisplayName": "Great Falls 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.907	 , "LAT":	34.624	}, "geometry": { "type": "Point", "coordinates": [ 	-80.907	,	34.624	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "CEDA_CAT", "DisplayName": "Cedar Creek 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.817	 , "LAT":	34.601	}, "geometry": { "type": "Point", "coordinates": [ 	-80.817	,	34.601	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WATR_CAT", "DisplayName": "Wateree 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.894	 , "LAT":	34.451	}, "geometry": { "type": "Point", "coordinates": [ 	-80.894	,	34.451	] } } ,


]
}
