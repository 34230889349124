import { Button } from "@mui/material";
import Plot from "react-plotly.js";
import {
  formatPlotData,
  generateLineStyle,
  generateMarkerStyle,
  chooseCustomYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  choosePlotTraceName,
  chooseFill,
  chooseFillColor,
  findHindcastStart,
  findPostShortTermEnd,
  setYRange,
} from "../Plots/PlotHelpers";
import { createShapesThumbnail } from "../Plots/Shapes";
import "../Plots/MainPlot.css";


export const ThumbnailPlot = ({ basin, basinClick }) => {
  const clipBasinName = (basinFullName) => {
    return basinFullName.split("_")[0];
  };

  const processedData = formatPlotData(basin.ts);

  const notRegularTS = (name) => {
    if (
      // name === "QPE_to_MAPCumulativePastPrecip" ||
      // name === "PreprocessHRRRCumulativeShortPrecip" ||
      // name === "PreprocessGFSCumulativeMedPrecip" ||
      name === "ThresholdsBooleanThreshold"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      let tsObject;
      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseCustomYAxis(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
          hovertemplate: 
            '%{y:.2f} in' +
            '<br>%{x}<br>' ,
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseCustomYAxis(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),
          showlegend:
            ts.name === "ThresholdsStreamflowThreshold" ||
            ts.name === "Preprocess_NWMMedRangeMax"
              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
          hovertemplate: 
            '%{y:.2f} in' +
            '<br>%{x}<br>' ,
        };
      }

      return tsObject;
    })
    .sort((a, b) => {
      // Define the desired order
      const customOrder = ["GFS","ECMWF","HRRR", "OBS", "MRMS"];
    
      // Helper function to get the index of a name in customOrder
      const getOrder = (name) => {
        if (name.includes("GFS")) return customOrder.indexOf("GFS");
        if (name.includes("ECMWF")) return customOrder.indexOf("ECMWF");
        if (name.includes("HRRR")) return customOrder.indexOf("HRRR");
        if (name.includes("OBS")) return customOrder.indexOf("OBS");
        if (name.includes("MRMS")) return customOrder.indexOf("MRMS");
        return customOrder.length; // For items not in customOrder, place them last
      };
    
      // Compare based on the custom order
      const orderA = getOrder(a.name);
      const orderB = getOrder(b.name);
    
      return orderA - orderB; // Sort by custom order
    });

  const shapesArray = createShapesThumbnail(plotableTS, basin.ts);

  return (
    <>
      <Button
        className="thumbnail-btn"
        onClick={() => basinClick(clipBasinName(basin.name))}
      >
        <Plot
          data={generatePlotDataFormat}
          config={{ displayModeBar: false }}
          layout={{
            autosize: true,
            title: {
              text: basin.name
                .split(/(?=[A-Z])/)
                .join(" ")
                .split("_")
                .join(" "),
              font: {
                color: "black",
                size: 14,
              },
            },
            showlegend: false,
            hovermode: "closest",
            
            yaxis1: {
              autoscale: true,
              title: {
                text: "POOL ELEV",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0, 0.4],
              rangemode: "tozero",
              tickfont: {
                size: 10,
              },
            },
            
            
            yaxis2: {
              autoscale: true,
              title: {
                text: "STRM FLOW",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0, 0.4],
              rangemode: "tozero",
              tickfont: {
                size: 10,
              },
            },
            yaxis3: {
              autoscale: true,
              title: {
                text: "(in)",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              domain: [0.47, 0.67],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y3"),
              tickfont: {
                size: 10,
              },
            },
            yaxis4: {
              autoscale: true,
              title: {
                text: "(in)",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              tickfont: {
                size: 10,
              },
              domain: [0.75, 0.95],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y4"),
            },
            xaxis: {
              title: {
                text: "Datetime (EST)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              mirror: "all",
              showgrid: true,
              showline: true,
              zeroline: true,
              range: [
                findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
                findPostShortTermEnd(basin.ts, "PreprocessHRRR"),
              ],
              tickfont: {
                size: 10,
              },
            },
            margin: { r: 5, l: 50, t: 30, b: 40 },
            shapes: shapesArray,
          }}
          useResizeHandler={true}
          className="thumbnail-plot"
        />
      </Button>
    </>
  );
};
