import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, 
        TileLayer, 
        GeoJSON, 
        LayerGroup, 
        Marker,
        Popup,
        LayersControl } from "react-leaflet";
import "./Map.css";
import { basinsYadkin } from "../../GIS/Yadkin_UI_Basins_Simple";
import { streams4Yadkin } from "../../GIS/Yadkin_UI_StreamsSO4+_Simple";
import { reservoirsYadkin } from "../../GIS/Yadkin_UI_Reservoirs_Simple";
import { basinsCatawba } from "../../GIS/Catawba_Basins";
import { riversCatawba } from "../../GIS/Catawba_Rivers_Simplified";
import { reservoirsCatawba } from "../../GIS/Catawba_Reservoirs";
import { reservoirsKeowee } from "../../GIS/Keowee_reservoirs";
import { reservoirsPigeon } from "../../GIS/Pigeon_reservoirs";
import { reservoirsNantahala } from "../../GIS/Nant_Waterbodies";
import { reservoirsTuckasegee } from "../../GIS/Tuck_Waterbodies";


import { basinsCatawbaCustom } from "../../GIS/Custom_catawba_Basins";
import { basinsYadkinCustom } from "../../GIS/Custom_yadkin_Basins";
import { basinsNantCustom } from "../../GIS/Custom_nant_Basins";
import { basinsTuckCustom } from "../../GIS/Custom_tuck_Basins";
import { basinsKeowee } from "../../GIS/Keowee_basins";
import { basinsPigeon } from "../../GIS/Pigeon_basins";

import { riversNant } from "../../GIS/Nant_Rivers";
import { riversTuck } from "../../GIS/Tuck_Rivers";
import { riversKeowee } from "../../GIS/Keowee_rivers";
import { riversPigeon } from "../../GIS/Pigeon_rivers";

import { squaresCatawba } from "../../GIS/Catawba_squares";
import { squaresYadkin } from "../../GIS/Yadkin_squares";
import { squaresNant } from "../../GIS/Nant_squares";
import { squaresTuck } from "../../GIS/Tuck_squares";
import { squaresKeowee } from "../../GIS/Keowee_squares";
import { squaresPigeon } from "../../GIS/Pigeon_squares";

import { arrowsCatawba } from "../../GIS/Catawba_arrow";
import { arrowsYadkin } from "../../GIS/Yadkin_arrow";
import { arrowsNant } from "../../GIS/Nant_arrow";
import { arrowsTuck } from "../../GIS/Tuck_arrow";
import { arrowsPigeon } from "../../GIS/Pigeon_arrow";
import { arrowsKeowee } from "../../GIS/Keowee_arrow";

import { CircularProgress } from "@mui/material";
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import square from "./square_icon.svg";
import SE from "./SE.svg";
import N from "./N.svg";
import NW from "./NW.svg";
import SW from "./SW.svg";


let SquareIcon = L.icon({
  iconUrl: square,
  shadowUrl: iconShadow,
  iconSize: [18, 18], // size of the icon
  shadowSize: [8, 12], // size of the shadow
  iconAnchor: [10, 9], // point of the icon which will correspond to marker's location
  shadowAnchor: [2, 2],  // the same for the shadow
  popupAnchor:  [0, -6] // point from which the popup should open relative to the iconAnchor
});
let arrowSE = L.icon({
  iconUrl: SE,
  iconSize: [37, 37], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});
let arrowSW = L.icon({
  iconUrl: SW,
  iconSize: [37, 37], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});
let arrowN = L.icon({
  iconUrl: N,
  iconSize: [24, 24], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});
let arrowNW = L.icon({
  iconUrl: NW,
  iconSize: [30, 30], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});


export const EvaluateMap = (props) => {
  const { basinClick, watershed, thresholds } = props;
  let basins, streams, reservoirs, lat, lng, default_zoom, points, bigbasins, basinColoring, arrows, arrowIcon;
  
  const fillCatSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
  
    //Refactored threshold if statements
    const alertPairs = [
      ["PLEA_CAT", "JAME_CAT"],
      ["LINV_CAT", "JAME_CAT"],
      ["JAME_CAT", "JAME_CAT"],
      ["MORG_CAT", "RHOD_CAT"],
      ["WARR_CAT", "RHOD_CAT"],
      ["JOHN_CAT", "RHOD_CAT"],
      ["RHOD_CAT", "RHOD_CAT"],
      ["MIDL_CAT", "HICK_CAT"],
      ["UPPL_CAT", "HICK_CAT"],
      ["HICK_CAT", "HICK_CAT"],
      ["LOWL_CAT", "LOOK_CAT"],
      ["LOOK_CAT", "LOOK_CAT"],
      ["HENR_CAT", "WYLI_CAT"],
      ["JACO_CAT", "WYLI_CAT"],
      ["SFOR_CAT", "WYLI_CAT"],
      ["INDC_CAT", "WYLI_CAT"],
      ["DUTC_CAT", "WYLI_CAT"],
      ["KILL_CAT", "WYLI_CAT"],
      ["LONC_CAT", "WYLI_CAT"],
      ["ALLI_CAT", "WYLI_CAT"],
      ["LONG_CAT", "WYLI_CAT"],
      ["WYLI_CAT", "WYLI_CAT"],
      ["MCDO_CAT", "MOUN_CAT"],
      ["MOUN_CAT", "MOUN_CAT"],
      ["FISY_CAT", "GREA_CAT"],
      ["WILC_CAT", "GREA_CAT"],
      ["FCRK_CAT", "GREA_CAT"],
      ["GREA_CAT", "GREA_CAT"],
      ["SUGP_CAT", "FISH_CAT"],
      ["LITS_CAT", "FISH_CAT"],
      ["SUGA_CAT", "FISH_CAT"],
      ["MCAL_CAT", "FISH_CAT"],
      ["CRRH_CAT", "FISH_CAT"],
      ["BELW_CAT", "FISH_CAT"],
      ["FISH_CAT", "FISH_CAT"],
      ["ROCK_CAT", "CEDA_CAT"],
      ["CEDA_CAT", "CEDA_CAT"],
      ["WATR_CAT", "WATR_CAT"],
      ["CAMD_CAT", "CAMD_CAT"],
    ];

    // Iterate over alertPairs and check each condition
    for (const [name1, name2] of alertPairs) {
      const firstItem = thresholds.find((thresholdItem) => thresholdItem.name === name1);
      const secondItem = thresholds.find((thresholdItem) => thresholdItem.name === name2);

      if (firstItem && firstItem.threshold >= 1){ 
        secondItem.threshold = 1;
      }
      
      if (
        firstItem &&
        firstItem.name === feature.properties.FEWS_ID &&
        secondItem &&
        secondItem.threshold >= 1
      ) {
        return alertStyle;
      }
    }

  // Default return if no alert conditions are met
  return defaultStyle;
};
  
  const fillYadSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
  
    //Refactored threshold if statements
    const alertPairs = [
      ["PATT_YAD", "KERR_YAD"],
      ["ELKC_YAD", "KERR_YAD"],
      ["KERR_YAD", "KERR_YAD"],
      ["REDD_YAD", "HIGH_YAD"],
      ["WILK_YAD", "HIGH_YAD"],
      ["ELKN_YAD", "HIGH_YAD"],
      ["ENON_YAD", "HIGH_YAD"],
      ["ARAR_YAD", "HIGH_YAD"],
      ["HUNT_YAD", "HIGH_YAD"],
      ["LYAD_YAD", "HIGH_YAD"],
      ["YADC_YAD", "HIGH_YAD"],
      ["MOCK_YAD", "HIGH_YAD"],
      ["CONF_YAD", "HIGH_YAD"],
      ["SYAD_YAD", "HIGH_YAD"],
      ["ABBO_YAD", "HIGH_YAD"],
      ["SECO_YAD", "HIGH_YAD"],
      ["HIGH_YAD", "HIGH_YAD"],
      ["TUCK_YAD", "TUCK_YAD"],
      ["NARR_YAD", "NARR_YAD"],
      ["UWHA_YAD", "TILL_YAD"],
      ["TILL_YAD", "TILL_YAD"],
      ["FALL_YAD", "FALL_YAD"],
      ["IRSH_YAD", "BLEW_YAD"],
      ["ROCK_YAD", "BLEW_YAD"],
      ["LITT_YAD", "BLEW_YAD"],
      ["NORW_YAD", "BLEW_YAD"],
      ["RKHM_YAD", "BLEW_YAD"],
      ["BLEW_YAD", "BLEW_YAD"],
    ];
  

    // Iterate over alertPairs and check each condition
    for (const [name1, name2] of alertPairs) {
      const firstItem = thresholds.find((thresholdItem) => thresholdItem.name === name1);
      const secondItem = thresholds.find((thresholdItem) => thresholdItem.name === name2);

      if (firstItem && firstItem.threshold >= 1){ 
        secondItem.threshold = 1;
      }
      
      if (
        firstItem &&
        firstItem.name === feature.properties.FEWS_ID &&
        secondItem &&
        secondItem.threshold >= 1
      ) {
        return alertStyle;
      }
    }

  // Default return if no alert conditions are met
  return defaultStyle;
};

  
  const fillNantSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "RAIN_NAN");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };
  

  const fillTuckSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "GLEN_TUC");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };
  
  
  if (watershed === "catawba") {
    basins = basinsCatawbaCustom;
    streams = riversCatawba;
    reservoirs = reservoirsCatawba;
    points = squaresCatawba;
    lat = 35.2;
    lng = -81.2;
    default_zoom = 8;
    bigbasins = basinsCatawba;
    basinColoring = fillCatSubbasin;
    arrows = arrowsCatawba;
    arrowIcon = arrowSE;
  }
  else if (watershed === "yadkin") {
    basins = basinsYadkinCustom;
    streams = streams4Yadkin;
    reservoirs = reservoirsYadkin;
    points = squaresYadkin;
    lat = 35.8;
    lng = -80.7;
    default_zoom = 8;
    bigbasins = basinsYadkin;
    basinColoring = fillYadSubbasin;
    arrows = arrowsYadkin;
    arrowIcon = arrowSE;
  }
  else if (watershed === "nant") {
    basins = basinsNantCustom;
    streams = riversNant;
    reservoirs = reservoirsNantahala;
    points = squaresNant;
    lat = 35.17;
    lng = -83.62;
    default_zoom = 10;
    bigbasins = basinsNantCustom;
    basinColoring = fillNantSubbasin;
    arrows = arrowsNant;
    arrowIcon = arrowN;
  }
  else if (watershed === "tuck") {
    basins = basinsTuckCustom;
    streams = riversTuck;
    reservoirs = reservoirsTuckasegee;
    points = squaresTuck;
    lat = 35.25;
    lng = -83.1;
    default_zoom = 10;
    bigbasins = basinsTuckCustom;
    basinColoring = fillTuckSubbasin;
    arrows = arrowsTuck;
    arrowIcon = arrowNW;
  }
  else if (watershed === "pigeon") { 
    basins = basinsPigeon;
    streams = riversPigeon;
    reservoirs = reservoirsPigeon;
    points = squaresPigeon;
    lat = 35.45;
    lng = -82.95;
    default_zoom = 10;
    bigbasins = basinsPigeon;
    basinColoring = fillTuckSubbasin;
    arrows = arrowsPigeon;
    arrowIcon = arrowNW;
  }
  else if (watershed === "keowee") {
    basins = basinsKeowee;
    streams = riversKeowee;
    reservoirs = reservoirsKeowee;
    points = squaresKeowee;
    lat = 34.95;
    lng = -82.95;
    default_zoom = 10;
    bigbasins = basinsKeowee;
    basinColoring = fillTuckSubbasin;
    arrows = arrowsKeowee;
    arrowIcon = arrowSW;
  }

  const zoom = default_zoom;
  const position = [lat, lng];
  const watershedRef = useRef();
  const streamsRef = useRef();
  const resRef = useRef();
  const pointsRef = useRef();

  const chooseBasinStyle = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1.5,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };


  const streamStyle = {
    fillColor: "blue",
    fillOpacity: 0.7,
    color: "blue",
    weight: 0.5,
    opacity: 1,
  };
  const bigBasinStyle = {
    fillOpacity: 0,
    color: "black",
    weight: 3,
    opacity: 1,
  };

  return (
    <>
      {!thresholds || basins.features.length < 1 ? (
        <CircularProgress />
      ) : (
        <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
          <LayersControl position="topright">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
               <LayersControl.Overlay checked name="Basins">
              <GeoJSON
                ref={watershedRef}
                key={Math.random()}
                data={bigbasins.features}
                style={bigBasinStyle}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Subbasins">
              <GeoJSON
                ref={watershedRef}
                // key={basins.features.properties}
                key={Math.random()}
                data={basins.features}
                // style={chooseBasinStyle}
                style={basinColoring}
                // onEachFeature={onEachBasin}
              />
            </LayersControl.Overlay>
              {/* <LayersControl.Overlay checked name="Watershed">
                <GeoJSON
                  ref={watershedRef}
                  key={Math.random()}
                  // key={basins.features.properties}
                  data={basins.features}
                  style={chooseBasinStyle}
                  // onEachFeature={onEachBasin}
                />
              </LayersControl.Overlay> */}
              <LayersControl.Overlay checked name="Streams">
                <LayerGroup>
                  <GeoJSON
                    ref={streamsRef}
                    key={streams.features}
                    data={streams.features}
                    style={streamStyle}
                  />
                  <GeoJSON
                    ref={resRef}
                    // key={reservoirs.features}
                    key={Math.random()}
                    data={reservoirs.features}
                    style={streamStyle}
                  />
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay checked name="Points">
                <LayerGroup>
                  {/* {points.features.map((elem, i) => {
                      return (
                          <Circle 
                            center={{lat:elem.properties.LAT, 
                                    lng: elem.properties.LON}}
                            pathOptions={{ color: 'yellow' }}
                            onEachFeature={onEachPoint}
                            radius={600}/>
                        )
                        })}   */}
                      {/* <GeoJSON
                        ref={pointsRef}
                        key={points.features}
                        style={squaresStyle}
                        data={points.features}
                        type='MultiPoint'
                        onEachFeature={onEachPoint}
                      /> */}
                      {points.features.map((elem, i) => (
                        <Marker
                          ref={pointsRef}
                          key={elem.properties.SUBBASIN_ID}
                          // key={points.features}
                          // key={park.properties.PARK_ID}
                          // style={squaresStyle}
                          // data={points.features}
                          position={[
                            elem.properties.LAT, 
                            elem.properties.LON
                          ]}
                          icon={SquareIcon}
                          eventHandlers={
                            // {onEachPoint}
                            {
                            mouseover: (event) => event.target.openPopup(),
                            click: (event) => basinClick(elem.properties.SUBBASIN_ID),
                            mouseout: (event) => event.target.closePopup()
                          }
                        }
                        >
                          <Popup>{elem.properties.DisplayName}</Popup>
                      </Marker>
                      ))}
                  </LayerGroup>              
              </LayersControl.Overlay>
              <LayersControl.Overlay checked name="General Flow Direction">
                <LayerGroup>
                {arrows.features.map((elem) => (
                  <Marker
                    ref={pointsRef}
                    key={Math.random()}
                    position={[
                      elem.properties.LAT, 
                      elem.properties.LON
                    ]}
                    icon={arrowIcon}
                  >
                </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      )}
    </>
  );
};