export const extractThresholds = (array) => {
  const thresholdsArray = array.map((basinItem) => {
    return {
      // name: basinItem.header.stationName.split("_")[0],
      name: basinItem.header.stationName,
      threshold: basinItem.events
        ? basinItem.events
            .map((timestep) => parseInt(timestep.value))
            .reduce((prev, next) => prev + next)
        : 0,
    };
  });

  return thresholdsArray;
};

export const processTSForThumbnails = (fullArray) => {
  // each basin object has its name, and an array of timeseries
  // {name: James,  ts: [{ts1}, {ts2}, {ts3}]}

  // create set of basin names
  const setOfBasins = new Set();
  fullArray.forEach((ts) => {
    setOfBasins.add(ts.header.locationId);
  });
  // create array of objects, each obj has a basin
  const processedTS4Thumbnails = [];
  setOfBasins.forEach((basin) => {
    processedTS4Thumbnails.push({ name: basin, ts: [] });
  });

  // then loop through fullArray and add each ts to the correct basin object
  fullArray.forEach((ts) => {
    processedTS4Thumbnails.forEach((basinObj) => {
      if (basinObj.name === ts.header.locationId) {
        basinObj.ts.push(ts);
      }
    });
  });

  return processedTS4Thumbnails;
};


// export const extractShortFlowForecastDateTime = (array) => {
//   const shortFlowForecastDateTime = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "ShortRange";
//   }).header.forecastDate;
//   return shortFlowForecastDateTime;
// };

// export const extractMedFlowForecastDateTime = (array) => {
//   const medFlowForecastDateTime = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "LastMedFlow";
//   }).header.forecastDate;
//   return medFlowForecastDateTime;
// };

// export const extractStarTimeseries = (array) => {
//   const starTimeseries = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "LastMedFlow";
//   });
//   return starTimeseries;
// };
export const extractSquareTimeseries = (allTs) => {

  // REORDER THUMBNAILS
  var sortOrder = ['RAIN_NAN','WHIT_NAN','DICK_NAN','HYDR_NAN','OUTD_NAN','HEWI_NAN','OUTD_NAN','SILV_NAN',
                  'GAGE_TUC',"DILL_TUC",//,'DILL_TUC','CANE_TUC','BELW_TUC','SAVA_TUC','CULL_TUC'
                  'PLEA_CAT','LINV_CAT','MORG_CAT','WARR_CAT','JOHN_CAT','UPPL_CAT','MIDL_CAT',
                  'LOWL_CAT','MCDO_CAT','DUTC_CAT','SFOR_CAT','BELW_CAT','SUGA_CAT','FCRK_CAT','ROCK_CAT',

                  "HENR_CAT",,"ALLI_CAT",,"CAMD_CAT",,"CRRH_CAT",,"FISY_CAT",,"INDC_CAT",,"JACO_CAT",,"KILL_CAT",
                  "LITS_CAT",,"LONC_CAT",,"LONG_CAT",,"MCAL_CAT",,"SUGP_CAT","WILC_CAT",


                  'PATT_YAD','ELKC_YAD','UPPR_YAD','REDD_YAD','WILK_YAD','ROAR_YAD','ELKN_YAD','MITC_YAD',
                  'FISH_YAD','ARAR_YAD','LYAD_YAD','ENON_YAD','YADC_YAD','CONF_YAD','HUNT_YAD','MOCK_YAD',
                  'SECO_YAD','SYAD_YAD','ABBO_YAD','UWHA_YAD','IRSH_YAD','ROCK_YAD','NORW_YAD','LITT_YAD',
                  "RKHM_YAD",

                  'WATE_PIG','CATA_PIG','HEPC_PIG','CANT_PIG','BETH_PIG','EFOR_PIG','LOGA_PIG',
                  'EAST_KEO'
                ]; 
  allTs.sort((a, b) => {
    let fa = a.header.locationId;
    let fb = b.header.locationId;
    return sortOrder.indexOf(fa) - sortOrder.indexOf(fb);
  });
  // allTs.forEach((ts) => {
  //   console.log(`${ts.header.locationId}`);
  // });

  const squareTimeseries = allTs.filter((ts) => {
    return (
      (ts.header.locationId === "ABBO_YAD") ||
      (ts.header.locationId === "ARAR_YAD") ||
      (ts.header.locationId === "REDD_YAD") ||
      (ts.header.locationId === "IRSH_YAD") ||
      (ts.header.locationId === "MOCK_YAD") ||
      (ts.header.locationId === "PATT_YAD") ||
      (ts.header.locationId === "ELKC_YAD") ||
      (ts.header.locationId === "HUNT_YAD") ||
      (ts.header.locationId === "LITT_YAD") ||
      (ts.header.locationId === "LYAD_YAD") ||
      (ts.header.locationId === "SECO_YAD") ||
      (ts.header.locationId === "MITC_YAD") ||
      (ts.header.locationId === "ROAR_YAD") ||
      (ts.header.locationId === "UWHA_YAD") ||
      (ts.header.locationId === "NORW_YAD") ||
      (ts.header.locationId === "ELKN_YAD") ||
      (ts.header.locationId === "ENON_YAD") ||
      (ts.header.locationId === "WILK_YAD") ||
      (ts.header.locationId === "YADC_YAD") ||
      (ts.header.locationId === "ROCK_YAD") ||
      (ts.header.locationId === "SYAD_YAD") ||
      (ts.header.locationId === "CONF_YAD") ||
      (ts.header.locationId === "FISH_YAD") ||
      (ts.header.locationId === "UPPR_YAD") ||

      (ts.header.locationId === "PLEA_CAT") ||
      (ts.header.locationId === "JOHN_CAT") ||
      (ts.header.locationId === "LOWL_CAT") ||
      (ts.header.locationId === "ROCK_CAT") ||
      (ts.header.locationId === "SFOR_CAT") ||
      (ts.header.locationId === "SUGA_CAT") ||
      (ts.header.locationId === "FCRK_CAT") ||
      (ts.header.locationId === "LINV_CAT") ||
      (ts.header.locationId === "MCDO_CAT") ||
      (ts.header.locationId === "MIDL_CAT") ||
      (ts.header.locationId === "UPPL_CAT") ||
      (ts.header.locationId === "DUTC_CAT") ||
      (ts.header.locationId === "WARR_CAT") ||
      (ts.header.locationId === "MORG_CAT") ||
      (ts.header.locationId === "BELW_CAT") ||

      (ts.header.locationId === "GAGE_TUC") ||
      (ts.header.locationId === "CANE_TUC") ||
      (ts.header.locationId === "SAVA_TUC") ||
      (ts.header.locationId === "CULL_TUC") ||
      (ts.header.locationId === "BELW_TUC") ||
      (ts.header.locationId === "DILL_TUC") ||
      
      (ts.header.locationId === "SILV_NAN") ||
      (ts.header.locationId === "OUTD_NAN") ||
      (ts.header.locationId === "HEWI_NAN") ||
      (ts.header.locationId === "RAIN_NAN") ||
      (ts.header.locationId === "DICK_NAN") ||
      (ts.header.locationId === "WHIT_NAN") ||
      (ts.header.locationId === "HYDR_NAN") ||


      (ts.header.locationId === "WATE_PIG") ||
      (ts.header.locationId === "CATA_PIG") ||
      (ts.header.locationId === "HEPC_PIG") ||
      (ts.header.locationId === "CANT_PIG") ||
      (ts.header.locationId === "BETH_PIG") ||
      (ts.header.locationId === "EFOR_PIG") ||
      (ts.header.locationId === "LOGA_PIG") ||
      (ts.header.locationId === "EAST_KEO") ||


      //News
      (ts.header.locationId === "HENR_CAT") ||
      (ts.header.locationId === "ALLI_CAT") ||
      (ts.header.locationId === "CAMD_CAT") ||
      (ts.header.locationId === "CRRH_CAT") ||
      (ts.header.locationId === "FISY_CAT") ||
      (ts.header.locationId === "INDC_CAT") ||
      (ts.header.locationId === "JACO_CAT") ||
      (ts.header.locationId === "KILL_CAT") ||
      (ts.header.locationId === "LITS_CAT") ||
      (ts.header.locationId === "LONC_CAT") ||
      (ts.header.locationId === "LONG_CAT") ||
      (ts.header.locationId === "MCAL_CAT") ||
      (ts.header.locationId === "SUGP_CAT") ||
      (ts.header.locationId === "WILC_CAT") ||
      (ts.header.locationId === "DILL_TUC") ||
      (ts.header.locationId === "RKHM_YAD") 


    );
  });
  // return processTSForThumbnails(allTs);
  return squareTimeseries;
};


// export const addCorrectPrecipTs = (allTs) => {
//   const filteredAllTs = allTs.filter((ts) => {
//     return (
//       (ts.header.parameterId === "MAP" &&
//         ts.header.moduleInstanceId === "Merge_MAP_HRRR_Nant_Forecast_custom") ||
//       (ts.header.parameterId === "MAP" &&
//         ts.header.moduleInstanceId === "Merge_MAP_HRRR_Tuck_Forecast_custom") ||
//       (ts.header.parameterId === "MAP" &&
//         ts.header.moduleInstanceId === "Merge_MAP_HRRR_Yadkin_Forecast_custom") ||
//       (ts.header.parameterId === "MAP" &&
//         ts.header.moduleInstanceId === "Merge_MAP_HRRR_Catawba_Forecast_custom")
//     );
//   });
//   return processTSForThumbnails(allTs);
// };


// export const addCorrectPrecipTs = (allTs) => {
//   // take in 2 new corrected precip ts lists and list of all ts
//   // remove out all the old precip ts
//   const filteredAllTs = allTs.filter((ts) => {
//     return (
//       (ts.header.parameterId !== "MAP" &&
//         ts.header.moduleInstanceId !== "Merge_MAP_HRRR_Nant_Forecast_custom") ||
//       (ts.header.parameterId !== "MAP" &&
//         ts.header.moduleInstanceId !== "Merge_MAP_HRRR_Tuck_Forecast_custom") ||
//       (ts.header.parameterId !== "MAP" &&
//         ts.header.moduleInstanceId !== "Merge_MAP_HRRR_Yadkin_Forecast_custom") ||
//       (ts.header.parameterId !== "MAP" &&
//         ts.header.moduleInstanceId !== "Merge_MAP_HRRR_Catawba_Forecast_custom")
//     );
//   });

//   // add in new precip ts
//   // const correctTs = filteredAllTs.concat(shortPpt, medPpt);

//   //then parse out into separate basins and proceed as before with thumbnails
//   return processTSForThumbnails(filteredAllTs);
// };

export const grabSelectedBasinTs = (allTs, selectedBasin) => {
  // find the object which matches selectedBasin
  // return that object's ts only!

  const basinOfInterest = allTs.find((obj) => {
    return obj.name.match(selectedBasin);
  });

  return basinOfInterest.ts;
};
