export const formatPlotData = (data) => {
  // we have an array of timeseries data, one item in the array is
  // a given ts for the location

  let processedTimeseries = [];
  data.forEach((ts) => {
    if (ts.events) {
      // loop through the single ts and grab all the
      // x + y coords formatted in two separate arrays
      const xCoords = getXCoords(ts.events);
      const yCoords = getYCoords(ts.events);

      // create a new object with the name of the ts,
      //the basin id(aka the name of the basin),
      //the x coords and the y coords
      const newObject = {
        name: ts.header.qualifierId
          ? ts.header.moduleInstanceId + ts.header.qualifierId[0]
          : ts.header.moduleInstanceId,
        basin: ts.header.locationId,
        x: xCoords,
        y: yCoords,
        forecastDateTime: ts.header.forecastDate,
      };
      processedTimeseries.push(newObject);
    }
  });

  return processedTimeseries;
};

const getXCoords = (array) => {
  const xCoords = [];
  array.forEach((event) => {
    xCoords.push(event.date + " " + event.time);
  });
  return xCoords;
};

const getYCoords = (array) => {
  const yCoords = [];
  array.forEach((event) => {
    yCoords.push(parseFloat(event.value));
  });
  return yCoords;
};

export const generateLineStyle = (name) => {
  let lineStyle = {};
  switch (name) {
    case "Preprocess_NWMAnalysis":
      lineStyle = { color: "rgb(80, 80, 80)", width: 2 };
      break;
    case "Preprocess_NWMShortRange":
      lineStyle = { color: "rgb(194, 48, 22)", width: 2 };
      break;
    case "Preprocess_NWMMedRangeMin":
      lineStyle = { color: "rgba(0, 102, 255, 0.1)", width: 2 };
      break;

    case "Preprocess_NWMMedRangeMax":
      lineStyle = { color: "rgba(0, 102, 255, 0.1)", width: 2 };
      break;
    case "Preprocess_NWMLastMedFlow":
      lineStyle = { color: "rgb(33, 97, 140)", width: 2 };
      break;
    case "Preprocess_NWMMedRangeAvg":
      lineStyle = { color: "rgb(33, 97, 140)", width: 2, dash: "dashdot" };
      break;
    case "ThresholdsStreamflowThreshold":
      lineStyle = { color: "black", width: 1 };
      break;
    case "Preprocess_NWMShortFlowAve":
      lineStyle = { color: "rgb(194, 48, 22)", width: 2, dash: "dashdot" };
      break;
    default:
      lineStyle = {};
  }

  return lineStyle;
};

export const generateMarkerStyle = (name) => {
  let markerStyle = {};
  switch (name) {
    case "PreprocessHRRR":
      markerStyle = { color: "rgb(194, 48, 22)" };
      break;
    case "PreprocessGFS":
      markerStyle = { color: "rgb(33, 97, 140)" };
      break;
    case "QPE_to_MAPmerged":
      markerStyle = { color: "rgb(80, 80, 80)" };
      break;

    default:
      markerStyle = {};
  }

  return markerStyle;
};

export const choosePlotTraceName = (name) => {
  let trace;
  switch (name) {
    case "PreprocessHRRR":
      trace = "ShortPrecip";
      break;
    case "QPE_to_MAPmerged":
      trace = "PastPrecip";
      break;
    case "Preprocess_NWMMedRangeAvg":
      trace = "MedFlowAve";
      break;
    case "Preprocess_NWMShortRange":
      trace = "ShortFlow";
      break;
    case "PreprocessGFS":
      trace = "MedPrecip";
      break;
    case "Preprocess_NWMAnalysis":
      trace = "PastFlow";
      break;
    case "Preprocess_NWMLastMedFlow":
      trace = "LastMedFlow";
      break;
    case "Preprocess_NWMMedRangeMax":
      trace = "MedFlowRANGEMax";
      break;
    case "Preprocess_NWMMedRangeMin":
      trace = "MedFlowRng";
      break;
    case "ThresholdsStreamflowThreshold":
      trace = "Threshold";
      break;
    case "Preprocess_NWMShortFlowAve":
      trace = "ShortFlowAve";
      break;

    default:
      trace = "";
  }

  return trace;
};

export const chooseCustomYAxis = (name) => {
  let yaxis;
  switch (name) {
    case "PreprocessHRRR":
      yaxis = "y3";
      break;
    case "QPE_to_MAPmerged":
      yaxis = "y3";
      break;
    case "QPE_to_MAPCumulativePastPrecip":
      yaxis = "y3";
      break;
    case "PreprocessHRRRCumulativeShortPrecip":
      yaxis = "y3";
      break;
    case "PreprocessGFS":
      yaxis = "y2";
      break;
    default:
      yaxis = "y1";
  }

  return yaxis;
};

export const chooseFill = (name) => {
  let fill;
  if (name === "Preprocess_NWMMedRangeMin") {
    fill = "tonexty";
  } else {
    fill = null;
  }
  return fill;
};

export const chooseFillColor = (name) => {
  let fillColor;
  if (
    name === "Preprocess_NWMMedRangeMin" ||
    name === "Preprocess_NWMMedRangeMax"
  ) {
    fillColor = "rgba(0, 102, 255, 0.3)";
  } else {
    fillColor = null;
  }
  return fillColor;
};

export const chooseType = (name) => {
  let type;
  switch (name) {
    case "PreprocessHRRR":
      type = "bar";
      break;
    case "PreprocessGFS":
      type = "bar";
      break;
    case "QPE_to_MAPmerged":
      type = "bar";
      break;

    default:
      type = "line";
  }

  return type;
};

export const chooseWidth = (name) => {
  let width;
  switch (name) {
    case "PreprocessHRRR":
      width = 3600000;
      break;
    case "PreprocessGFS":
      width = 3600000;
      break;
    case "QPE_to_MAPmerged":
      width = 3600000;
      break;

    default:
      width = 3600000;
  }

  return width;
};

export const chooseOffset = (name) => {
  let offset;
  switch (name) {
    // we need this since the bars naturally plot to the right, but we want them to the left
    case "PreprocessHRRR":
      offset = -3600000;
      break;
    case "PreprocessGFS":
      offset = -3600000;
      break;
    case "QPE_to_MAPmerged":
      offset = -3600000;
      break;

    default:
      offset = -3600000;
  }

  return offset;
};

export const findHindcastStart = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);

  return tsStart.setTime(tsStart.getTime());
};

export const findPreMediumForecastMiddle = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);
  const dateOffset = 24 * 60 * 60 * 1000 * 0.7; //0.7 days
  return tsStart.setTime(tsStart.getTime() - dateOffset);
};

export const findPreMediumForecastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  return new Date(tsOfInterest?.x[0]);
};

export const findHindcastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  return new Date(tsOfInterest?.x[tsOfInterest.x.length - 1]);
};

export const findPostShortTermStart = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const rectangleStart = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 1.25; //1.25 days or 18 hours
  return rectangleStart?.getTime() + dateOffset;
};

export const findPostShortTermEnd = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 11; //11 days to account for different start/end times of forecast and timezone
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );

  return rectangleEnd?.getTime() + dateOffset;
};

export const findPostShortTermMiddle = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 5.5; //5.5 days
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );

  return rectangleEnd?.getTime() + dateOffset;
};

export const thresholdVisible = (array, basin) => {
  if (array.filter((item) => item.name === basin).length > 0) {
    return true;
  } else {
    return false;
  }
};

export const findThresholdValue = (array) => {
  const tsOfInterest = array.find(
    (ts) =>
      ts?.header?.moduleInstanceId === "Thresholds" &&
      ts?.header?.qualifierId[0] === "StreamflowThreshold"
  );

  if (tsOfInterest) {
    return parseInt(tsOfInterest?.events[0].value);
  } else {
    return 0;
  }
};

export const formatDateForTitle = (originalDate) => {
  const dateObj = new Date(originalDate);

  return dateObj.toLocaleString({
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const findCenterOfShortPrecipForecast = (forecastDateTime) => {
  const forecastStart = Date.parse(
    forecastDateTime.date + " " + forecastDateTime.time
  );
  // add 9 hours (32400000 ms in 9 hours)
  return new Date(forecastStart).getTime() + 32400000;
};

export const createShortCumulativePrecipValuesArray = (ts) => {
  // calculate the total precip amount in the 18 hours forecast period
  const sum = !ts?.events
    ? "No data"
    : ts.events
        .map((timestep) => parseFloat(timestep.value))
        .reduce((prev, next) => prev + next);

  if (ts?.header?.forecastDate) {
    return {
      wfpType: "accumulation_anno",
      aggregation_name: "SRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 0.98,
      xanchor: "center",
      // fn to find center of the short term forecast period
      x: findCenterOfShortPrecipForecast(ts.header.forecastDate),
      yanchor: "middle",
      text: typeof sum === "number" ? sum.toFixed(2) : sum,
      showarrow: false,
      font: {
        color: "rgb(194, 48, 22)",
        size: 11,
      },
    };
  }
};

const findCenterOfPastPrecip = (forecastDateTime) => {
  const forecastStart = Date.parse(
    forecastDateTime.date + " " + forecastDateTime.time
  );
  // add 18 hours (64800000 ms in 18 hours)
  return new Date(forecastStart).getTime() + 64800000;
};

export const createPastCumulativePrecipValuesArray = (ts) => {
  // calculate the total precip amount in the 36 hours historic period
  const sum = !ts?.events
    ? "No data"
    : ts?.events
        .map((timestep) => parseFloat(timestep.value))
        .reduce((prev, next) => prev + next);

  if (ts?.header?.startDate) {
    return {
      wfpType: "accumulation_anno",
      aggregation_name: "SRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 0.98,
      xanchor: "center",
      // add function to find the center of the historic past 36 hour period
      x: findCenterOfPastPrecip(ts.header?.startDate),
      yanchor: "middle",
      text: typeof sum === "number" ? sum.toFixed(2) : sum,
      showarrow: false,
      font: {
        color: "rgb(80, 80, 80)",
        size: 11,
      },
    };
  }
};

export const calcPastCumulativePrecip = (ts) => {
  // calculate the total precip amount in the 36 hours historic data period
  const sum = ts.y
    .map((value) => parseFloat(value))
    .reduce((prev, next) => prev + next);
  return sum.toFixed(2);
};

export const createMedCumulativePrecipValuesArray = (ts) => {
  // extract the total precip amount in each 24 hours for the 10 day forecast
  // last value in each day at 23:00 should be the value of the day's cumulative ppt
  // get that value and the date in an object

  // group timesteps by day midnight - 11 pm
  // this returns an object with key = day and value = array of precip values (but as strings)
  const groupByDay = ts?.events?.reduce(function (obj, timestep) {
    obj[timestep.date] = obj[timestep.date] || [];
    obj[timestep.date].push(timestep.value);
    return obj;
  }, {});

  let arrayToAddToAnnotations = [];

  if (groupByDay) {
    const arrayOfMedCumulPrecip = Object.keys(groupByDay).map(function (key) {
      return {
        day: key,
        cumulPrecip: groupByDay[key]
          .map((value) => parseFloat(value))
          .reduce((prev, next) => prev + next),
      };
    });
    // now loop through this array to create the annotation for each one
    arrayOfMedCumulPrecip.forEach((daySumPpt) => {
      arrayToAddToAnnotations.push({
        wfpType: "accumulation_anno",
        aggregation_name: "MRPB",
        visible: true,
        xref: "x",
        yref: "paper",
        y: 0.7,
        xanchor: "center",
        x: daySumPpt.day + " 12:00:00",
        yanchor: "middle",
        text: daySumPpt.cumulPrecip.toFixed(2),
        showarrow: false,
        font: {
          color: "rgb(33, 97, 140)",
          size: 11,
        },
      });
    });
  }

  return arrayToAddToAnnotations;
};

export const calculateMedPrecipBarShapePositions = (ts) => {
  const arrayToAddToShapes = [];
  const setOfDates = new Set();

  ts?.events?.forEach((timestep) => {
    setOfDates.add(timestep.date);
  });

  setOfDates.forEach((date) => {
    arrayToAddToShapes.push({
      wfpType: "accumulation_line",
      aggregation_name: "MRPB",
      visible: true,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: date,
      y0: 0.68,
      x1: date,
      y1: 0.72,
      line: {
        color: "rgb(33, 97, 140)",
        width: 1,
      },
    });
  });
  //remove first line since first line is already plotted
  // shift mutates the actual array
  arrayToAddToShapes.shift();
  return arrayToAddToShapes;
};

export const setYRange = (data, yaxis) => {
  // default y max is set to 1, but if the data have larger values than 1, this will scale the yaxis range up to match the data
  const thisYaxisTs = data.filter((ts) => {
    return ts.yaxis === yaxis;
  });

  let max = 1;

  thisYaxisTs.forEach((ts) => {
    ts.y.forEach((value) => {
      if (value > max) {
        max = value * 1.2;
      }
    });
  });

  return [0, max];
};
