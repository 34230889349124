export const squaresYadkin = {
"type": "FeatureCollection",
"name": "yadkin_squares",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ABBO_YAD", "DisplayName": "Abbotts Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.931	 , "LON":	-80.133	}, "geometry": { "type": "Point", "coordinates": [ 	-80.133	,	35.931	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ARAR_YAD", "DisplayName": "Ararat River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.55	 , "LON":	-80.623	}, "geometry": { "type": "Point", "coordinates": [ 	-80.623	,	36.55	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ELKC_YAD", "DisplayName": "Elk Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.151	 , "LON":	-81.511	}, "geometry": { "type": "Point", "coordinates": [ 	-81.511	,	36.151	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "HUNT_YAD", "DisplayName": "Hunting Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.084	 , "LON":	-80.901	}, "geometry": { "type": "Point", "coordinates": [ 	-80.901	,	36.084	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "LITT_YAD", "DisplayName": "Little River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.526	 , "LON":	-79.84	}, "geometry": { "type": "Point", "coordinates": [ 	-79.84	,	35.526	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "LYAD_YAD", "DisplayName": "Little Yadkin River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.346	 , "LON":	-80.384	}, "geometry": { "type": "Point", "coordinates": [ 	-80.384	,	36.346	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "RKHM_YAD", "DisplayName": "Pee Dee nr Rockingham	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.004	 , "LON":	-79.826	}, "geometry": { "type": "Point", "coordinates": [ 	-79.826	,	35.004	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "REDD_YAD", "DisplayName": "Reddies River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.276	 , "LON":	-81.277	}, "geometry": { "type": "Point", "coordinates": [ 	-81.277	,	36.276	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "IRSH_YAD", "DisplayName": "Rocky abv Irish Buffalo	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.395	 , "LON":	-80.719	}, "geometry": { "type": "Point", "coordinates": [ 	-80.719	,	35.395	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ROCK_YAD", "DisplayName": "Rocky below Long Cr	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.319	 , "LON":	-80.445	}, "geometry": { "type": "Point", "coordinates": [ 	-80.445	,	35.319	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "NORW_YAD", "DisplayName": "Rocky nr Norwood	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.017	 , "LON":	-80.395	}, "geometry": { "type": "Point", "coordinates": [ 	-80.395	,	35.017	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "SECO_YAD", "DisplayName": "Second Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.658	 , "LON":	-80.711	}, "geometry": { "type": "Point", "coordinates": [ 	-80.711	,	35.658	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "SYAD_YAD", "DisplayName": "South Yadkin at confluence	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.819	 , "LON":	-80.746	}, "geometry": { "type": "Point", "coordinates": [ 	-80.746	,	35.819	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "MOCK_YAD", "DisplayName": "South Yadkin at Mocksville	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.936	 , "LON":	-80.93	}, "geometry": { "type": "Point", "coordinates": [ 	-80.93	,	35.936	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "UWHA_YAD", "DisplayName": "Uwharrie River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.69	 , "LON":	-79.973	}, "geometry": { "type": "Point", "coordinates": [ 	-79.973	,	35.69	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "CONF_YAD", "DisplayName": "Yadkin at confluence	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.928	 , "LON":	-80.53	}, "geometry": { "type": "Point", "coordinates": [ 	-80.53	,	35.928	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ELKN_YAD", "DisplayName": "Yadkin at Elkin	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.272	 , "LON":	-81.052	}, "geometry": { "type": "Point", "coordinates": [ 	-81.052	,	36.272	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ENON_YAD", "DisplayName": "Yadkin at Enon	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.354	 , "LON":	-80.677	}, "geometry": { "type": "Point", "coordinates": [ 	-80.677	,	36.354	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "PATT_YAD", "DisplayName": "Yadkin at Patterson	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.067	 , "LON":	-81.599	}, "geometry": { "type": "Point", "coordinates": [ 	-81.599	,	36.067	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "WILK_YAD", "DisplayName": "Yadkin at Wilkesboro	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.112	 , "LON":	-81.206	}, "geometry": { "type": "Point", "coordinates": [ 	-81.206	,	36.112	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "YADC_YAD", "DisplayName": "Yadkin at Yadkin College	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.086	 , "LON":	-80.425	}, "geometry": { "type": "Point", "coordinates": [ 	-80.425	,	36.086	] } } ,
         

]
}

