import Plot from "react-plotly.js";
import {
  formatPlotData,
  generateLineStyle,
  generateMarkerStyle,
  toggleLegend,
  chooseCustomYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  choosePlotTraceName,
  chooseFill,
  chooseFillColor,
  formatDateForTitle,
  formatNameForTitle,
  setYRange,
  setYPoolRange,
  setYGenSpillRange,

  addDays,
  subtractDays,
  chooseLegendGroup
} from "./PlotCustomHelpers";
import { MoreInfo } from "../Layouts/MoreInfo";
import { getT0 } from "../../Utils/customHelpers";
import "./MainPlot.css";
import { createShapesMainCustom } from "./ShapesCustom";
import { createAnnotationsCustom } from "./AnnotationsCustom";


export const MainCustomPlot = ({ selectedBasin, allTs, thresholds }) => {

  const timeseries = allTs.filter(ts => (ts.header.locationId).startsWith(selectedBasin));
  //console.log("timeseries: ", timeseries);
  const processedData = formatPlotData(timeseries);

  const notRegularTS = (name) => {
    if ( //Excludes Timeseries from Plots
      name === "Preprocess_QR" ||
/*       name === "ThresholdsCustomBooleanThreshold" ||
      name === "ThresholdsBooleanThreshold" ||
      name === "ThresholdsCustomBooleanThresholdundefined" ||
      name === "ThresholdsCustomValueThresholdundefined" || */
      name === "undefinedAdjECMWF" ||
      name.includes("QR")

    ) {
      return true;
    } else {
      return false;
    }
  };

  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));
  getT0(plotableTS);

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      let tsObject;
      //console.log("Name: ", ts.name);

      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseCustomYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          showlegend:
            ts.name.includes("Merge_MAP_") && !ts.name.includes("cumulative")
            //ts.name.includes("MRMS")
              ? false
              : true,
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
          hovertemplate:
            '%{y:.2f}',// +
           // '<br>%{x|%m/%d/%y}<br>',
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseCustomYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),
          hovertemplate:
            '%{y:.2f}', //+
            //'<br>%{x|%m/%d/%y}<br>',
          showlegend: //Exclude entries for legend.  Edit further
              //ts.name.includes("HP") ||
              ts.name.includes("RW") ||
              ts.name.includes("Adj") ||
              //ts.name.includes("Threshold") ||
              ts.name.includes("QT") ||
              ts.name.includes("VE") ||
              ts.name.includes("UNREG")  ||
              ts.name.includes("QI") ||
              ts.name.includes("QG") ||
              ts.name.includes("Preprocess_PI_QT") 

              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
        };
      }

      return tsObject;
    })
    .sort((a, b) => {
      // Define the desired order
      const customOrder = ["GFS","ECMWF","HRRR","Threshold", "OBS", "MRMS"];
    
      // Helper function to get the index of a name in customOrder
      const getOrder = (name) => {
        if (name.includes("GFS")) return customOrder.indexOf("GFS");
        if (name.includes("ECMWF")) return customOrder.indexOf("ECMWF");
        if (name.includes("HRRR")) return customOrder.indexOf("HRRR");
        if (name.includes("OBS")) return customOrder.indexOf("OBS");
        if (name.includes("MRMS")) return customOrder.indexOf("MRMS");
        if (name.includes("Threshold")) return customOrder.indexOf("Threshold");

        return customOrder.length; // For items not in customOrder, place them last
      };
    
      // Compare based on the custom order
      const orderA = getOrder(a.name);
      const orderB = getOrder(b.name);
    
      return orderA - orderB; // Sort by custom order
    });
  const shapesArray = createShapesMainCustom(
    selectedBasin
  );
  /* Causing bugs due to empty TS
    const annotationsArray = createAnnotationsCustom(
      timeseries,
      thresholds,
      selectedBasin
    );
  End edit */
  return (
    <div className="main-plot-custom">
      <div className="plot-outer">
        <Plot
          data={generatePlotDataFormat}
          layout={{
            autosize: true,
            title: {
              text:
                formatNameForTitle(selectedBasin) +
                // selectedBasin.split(/(?=[A-Z])/).join(" ") +
                ": " +
                formatDateForTitle(
                  // plotableTS.find((ts) => ts.name === "Merge_MAP_GFS_Tuck_Forecast_customcumulative")
                  plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                    .forecastDateTime.date +
                  "T" +
                  // plotableTS.find((ts) => ts.name === "Merge_MAP_GFS_Tuck_Forecast_customcumulative")
                  plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                    .forecastDateTime.time
                ),
              font: {
                color: "black",
                size: 20,
              },
            },
            legend: { y: -0.1, orientation: "h" },
            showlegend: true,
            //hovermode: "closest",
            hovermode: 'x unified',

            yaxis1: {
              autoscale: true,
              title: {
                text: "Pool Elevation<br> (ft)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".1f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".1f",
              zeroline: true,
              domain: [0, 0.24],
              //rangemode: "nonnegative",
              range: setYPoolRange(selectedBasin,generatePlotDataFormat,"y1"),
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
              showlegend: false
            },

            yaxis2: {
              autoscale: true,
              title: {
                text: "Outflow<br> (cfs)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0.26, 0.49],
              rangemode: "tozero",
              range: setYGenSpillRange(generatePlotDataFormat, "y2"),
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
            },

            yaxis3: {
              autoscale: true,
              title: {
                text: "Inflow<br> (cfs)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0.51, 0.74],
              rangemode: "tozero",
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
            },

            yaxis4: {
              autoscale: true,
              title: {
                text: "Hrly. Precip<br>(in)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              domain: [0.76, 1.00],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y4"),
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
              side: "left",
            },

            yaxis5: {
              autoscale: true,
              title: {
                text: "Generation <br>(MWh)",
                font: {
                  size: 10
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: false,
              showline: true,
              showticklabels: true,
              tickformat: ",",
              zeroline: true,
              //domain: [0.76, 1.00],
              rangemode: "nonnegative",
              range: setYGenSpillRange(generatePlotDataFormat, "y5"),
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
              overlaying: 'y2',
              side: 'right',
              position: .90,
              //anchor: 'x',
            },

            yaxis6: {
              autoscale: true,
              title: {
                text: "Accum. Precip<br>(in)",
                font: {
                  size: 10
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: false,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              //domain: [0.76, 1.00],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y6"),
              fixedrange: true, 
              tickfont: {
                size: 11,
              },
              overlaying: 'y4',
              side: 'right',
              position: .90,
              //anchor: 'x',
            },
            xaxis: {
              title: {
                text: "Datetime",
                font: {
                  size: 11,
                },
              },
              gridcolor: "lightgrey",
              mirror: "all",
              showgrid: true,
              showline: true,
              zeroline: true,
              // //TO DO set to hindcast start, max is forecast + 10 ish days
              range: [subtractDays(Date.now(), 2), addDays(Date.now(), 10)],
              tickfont: {
                size: 11,
              },
              domain: [0, .90],
            },
            margin: { t: 45, r: 8 },
            //annotations: annotationsArray, //Removed for now until TS comes in
            shapes: shapesArray
          }}
          useResizeHandler={true}
          className="plot-inner"
        />
      </div>
      <div className="info-div">
        <MoreInfo name={selectedBasin} />
      </div>
    </div>
  );

};
