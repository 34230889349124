export const starsYadkin = {
"type": "FeatureCollection",
"name": "yadkin_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "KERR_YAD", "DisplayName": "W Kerr Scott Res	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	36.112	 , "LON":	-81.405	}, "geometry": { "type": "Point", "coordinates": [ 	-81.405	,	36.112	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "HIGH_YAD", "DisplayName": "High Rock Lake	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.679	 , "LON":	-80.359	}, "geometry": { "type": "Point", "coordinates": [ 	-80.359	,	35.679	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "TUCK_YAD", "DisplayName": "Tuckertown Reservoir	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.569	 , "LON":	-80.186	}, "geometry": { "type": "Point", "coordinates": [ 	-80.186	,	35.569	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "NARR_YAD", "DisplayName": "Narrows Reservoir	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.487	 , "LON":	-80.109	}, "geometry": { "type": "Point", "coordinates": [ 	-80.109	,	35.487	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "FALL_YAD", "DisplayName": "Falls Dam	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.401	 , "LON":	-80.086	}, "geometry": { "type": "Point", "coordinates": [ 	-80.086	,	35.401	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "TILL_YAD", "DisplayName": "Lake Tillery	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.343	 , "LON":	-80.084	}, "geometry": { "type": "Point", "coordinates": [ 	-80.084	,	35.343	] } } ,
    { "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "BLEW_YAD", "DisplayName": "Blewett Falls Lake	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LAT":	35.137	 , "LON":	-79.989	}, "geometry": { "type": "Point", "coordinates": [ 	-79.989	,	35.137	] } } ,

    
]
}
